export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SIGNUP_EMAIL = "SIGNUP_EMAIL";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const PROFILE_PICK_SUCCESS = "PROFILE_PICK_SUCCESS";
export const PROFILE_CHANGE_SUCCESS = "PROFILE_CHANGE_SUCCESS";
export const GET_ALL_CONTENT_SUCCESS = "GET_ALL_CONTENT_SUCCESS";
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";
export const GET_WATCHLIST_SUCCESS = "GET_WATCHLIST_SUCCESS";
export const ADD_WATCHLIST_SUCCESS = "ADD_WATCHLIST_SUCCESS";
export const REMOVE_WATCHLIST_SUCCESS = "REMOVE_WATCHLIST_SUCCESS";
